.highlight {
  fill: var(--text-primary);
}

.shadow {
  fill: var(--text-secondary);
}

svg {
  width: 4rem;
  height: 4rem;
}

@media (max-width: 768px) {
  svg {
    width: 3rem;
    height: 3rem;
  }
}
