* {
  transition: color 0s ease-in;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --fontsize-title-hero: 3.2rem;
  --fontsize-title-project: 1.2rem;
  --fontsize-subtitle-hero: 3rem;
  --fontsize-description-hero: 1.5rem;

  --fontsize-title: 2.4rem;
  --fontsize-title-theme: 2rem;
}

@media (max-width: 768px) {
  :root {
    --fontsize-title-hero: 2.4rem;
    --fontsize-title-project: 1rem;
    --fontsize-subtitle-hero: 1.8rem;
    --fontsize-description-hero: 1.2rem;

    --fontsize-title: 2rem;
    --fontsize-title-theme: 2rem;
  }
}

@media (max-width: 768px) {
  :root {
    --fontsize-title: 1.8rem;
    --fontsize-title-theme: 2rem;
  }
}

[data-theme="blue"] {
  --text-gradient: -webkit-linear-gradient(-225deg, #4ae4a7 30%, #22e1ff 100%);
  --text-primary: #cbe2ea;
  --text-secondary: #a0b0b4;
  --color-opaque: rgba(74, 228, 166, 0.5);
}

[data-theme="orange"] {
  --text-gradient: -webkit-linear-gradient(-225deg, #ffed21 30%, #ff7a21 100%);
  --text-primary: #ffecde;
  --text-secondary: #c4b6ad;
  --color-opaque: rgba(255, 237, 33, 0.5);
}

[data-theme="purple"] {
  --text-gradient: -webkit-linear-gradient(-225deg, #ffadff 30%, #ab77ff 100%);
  --text-primary: #f7deff;
  --text-secondary: #baadc4;
  --color-opaque: rgba(255, 173, 255, 0.5);
}

[data-theme="green"] {
  --text-gradient: -webkit-linear-gradient(-225deg, #eaff9e 30%, #7aff6b 100%);
  --text-primary: #e1ffde;
  --text-secondary: #adc4ad;
  --color-opaque: rgba(234, 255, 158, 0.5);
}

body {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: auto;
  text-align: center;
  width: 100%;

  font-family: "Fira Code";

  background-color: #181818;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::selection {
  background-color: var(--text-primary);
  color: #181818;
}

body::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

body::-webkit-scrollbar-track {
  background: #181818;
}

body::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 0.2rem 0rem 0rem 0.2rem;
}
